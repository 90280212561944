

























































































































































































































@import '~mixins';

.drag_to_answer {
  position: absolute;
  top: 60%;
  left: -42%;
  display: block;
  max-width: 40%;
  margin: 0;
  cursor: inherit;
  z-index: 99;

  @include res('xs') {
    display: none;
  }
}
